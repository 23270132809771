import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer, DrawerHeader, DrawerBody, Alert, Link, Typography
} from '@one-thd/sui-atomic-components';
import { DRAWER_HEADER, ERROR_MESSAGE } from '../../helpers/constants';

const DeliveryContentDrawerError = ({ handleOnClose, isQuickViewOpen, refetch }) => {

  return (
    <Drawer
      open={isQuickViewOpen}
      onClose={handleOnClose}
      position="right"
    >
      <DrawerHeader title={DRAWER_HEADER} onClose={handleOnClose} />
      <DrawerBody>
        <Alert status="error">
          <Typography>{ERROR_MESSAGE}</Typography>
          <div className="sui-pt-2">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link onClick={refetch()}>

              Refresh
            </Link>
          </div>
        </Alert>
      </DrawerBody>
    </Drawer>
  );
};

export default DeliveryContentDrawerError;

DeliveryContentDrawerError.propTypes = {
  handleOnClose: PropTypes.func,
  isQuickViewOpen: PropTypes.bool,
  refetch: PropTypes.func.isRequired
};

DeliveryContentDrawerError.defaultProps = {
  handleOnClose: () => {
  },
  isQuickViewOpen: false,
};
