import React from 'react';
import PropTypes from 'prop-types';
import './delivery-options-drawer.scss';
import { Drawer, DrawerHeader, DrawerBody, Typography } from '@one-thd/sui-atomic-components';
import {
  capitalizeFirstLetter,
  formatDate,
  formatDateWithDayOfWeek,
  getDeliveryDateAvailability,
  isDeliveryTimelineTodayOrTomorrow
} from '../../helpers/utils';
import { DeliveryOptionCard } from './DeliveryOptionCard';
import {
  BODFS_DELIVERY,
  BUY_ONLINE_DELIVER_FROM_STORE,
  DRAWER_FOOTER,
  DRAWER_HEADER,
  SHIP_TO_HOME,
  STH_DELIVERY,
  SIX_HOUR_CAR_BODFS_MESSAGE,
  CHOOSE_BODFS_MESSAGE
} from '../../helpers/constants';

export const DeliveryDrawer = ({
  sthServices,
  bodfsService,
  isQuickViewOpen,
  handleOnClose,
  channel,
  showTodayOrTomorrow = null
}) => {

  const checkTodayTomorrowDate = (startDate, endDate, formatDateWithRange = null) => {
    if (startDate === endDate && showTodayOrTomorrow) {
      return showTodayOrTomorrow(endDate, true, '', formatDate(endDate, false));
    }
    return formatDateWithRange ? formatDateWithRange(startDate, endDate) : '';
  };

  const sthDate = isDeliveryTimelineTodayOrTomorrow(sthServices[0]?.deliveryTimeline)
    ? capitalizeFirstLetter(getDeliveryDateAvailability(sthServices[0]?.deliveryTimeline)?.toLowerCase())
    : checkTodayTomorrowDate(sthServices[0]?.deliveryDates?.startDate,
      sthServices[0]?.deliveryDates?.endDate,
      formatDateWithDayOfWeek);

  const bodfsDate = getDeliveryDateAvailability(bodfsService[0]?.deliveryTimeline)?.toLowerCase();
  const hasSameDayCarDelivery = bodfsService[0]?.hasSameDayCarDelivery || false;
  const renderSixHourCarMessage = () => (
    <div className={`DeliveryDrawer__deliveryEstSixHour ${channel}`}>
      <span
        className={`DeliveryDrawer--delivery-express-truck-icon DeliveryDrawer--delivery-express-truck-icon--padding
      ${channel}`}
      />
      <span className={`DeliveryDrawer__sixHour ${channel}`}>
        {SIX_HOUR_CAR_BODFS_MESSAGE}&nbsp;
      </span>
      <span
        className={`DeliveryDrawer__sixHourNext ${channel}`}
      >{CHOOSE_BODFS_MESSAGE}
      </span>
    </div>
  );

  const renderBODFSMessage = () => (
    <div className="sui-pb-2">{BODFS_DELIVERY}&nbsp;
      <span className="sui-font-bold">{capitalizeFirstLetter(bodfsDate)}</span>
    </div>
  );

  return (
    // temporary fix for the z-index of live chat and feedback button having too high of a z-index
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <>
      <Drawer
        open={isQuickViewOpen}
        onClose={handleOnClose}
        position="right"
        className="z-index-2000000010"
      >
        <DrawerHeader title={DRAWER_HEADER} onClose={handleOnClose} />
        <DrawerBody>
          {sthServices.length > 0 && (
            <div className={bodfsService.length > 0 ? 'sui-pb-5' : ''}>
              <div className="sui-pb-2">
                <Typography>
                  {STH_DELIVERY}&nbsp;
                  <span className="sui-font-bold">{sthDate}</span>
                </Typography>
              </div>
              <div className="sui-border-solid sui-border-t-1 sui-pb-5" />
              {
                sthServices.map((deliveryOption, i) => {
                  return (
                    <DeliveryOptionCard
                      channel={channel}
                      deliveryType={SHIP_TO_HOME}
                      deliveryOption={deliveryOption}
                      key={i}
                    />
                  );
                })
              }
            </div>
          )}

          {bodfsService.length > 0 && (
            <div>
              <Typography>
                {hasSameDayCarDelivery ? renderSixHourCarMessage() : renderBODFSMessage()}
              </Typography>

              <div className="sui-border-solid sui-border-t-1 sui-pb-5" />
              <div>
                {
                  bodfsService.map((deliveryOption) => {
                    return (
                      <DeliveryOptionCard
                        channel={channel}
                        deliveryType={BUY_ONLINE_DELIVER_FROM_STORE}
                        deliveryOption={deliveryOption}
                      />
                    );
                  })
                }
              </div>
            </div>
          )}
          <div className="sui-flex sui-flex-row-reverse sui-pt-6">
            <Typography>
              {DRAWER_FOOTER}
            </Typography>
          </div>
        </DrawerBody>
      </Drawer>
    </>
  );
};

DeliveryDrawer.propTypes = {
  isQuickViewOpen: PropTypes.bool,
  handleOnClose: PropTypes.func,
  sthServices: PropTypes.instanceOf(Array).isRequired,
  bodfsService: PropTypes.instanceOf(Array).isRequired,
  channel: PropTypes.string,
  showTodayOrTomorrow: PropTypes.func,
};

DeliveryDrawer.defaultProps = {
  isQuickViewOpen: false,
  handleOnClose: () => {
  },
  channel: null,
  showTodayOrTomorrow: null,
};
