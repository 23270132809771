import React from 'react';
import './delivery-options-drawer.scss';
import {
  Drawer, DrawerHeader, DrawerBody, SkeletonLine, SkeletonContent
} from '@one-thd/sui-atomic-components';
import PropTypes from 'prop-types';
import { DRAWER_HEADER } from '../../helpers/constants';

export const DeliveryContentDrawerLoader = ({ handleOnClose, isQuickViewOpen }) => {

  return (
    <Drawer
      open={isQuickViewOpen}
      onClose={handleOnClose}
      position="right"
    >
      <DrawerHeader title={DRAWER_HEADER} onClose={handleOnClose} />
      <DrawerBody>
        <div className="sui-pt-5 sui-pb-6">
          <SkeletonLine variant="multi" numberOfLines={3} />
        </div>
        <div className="sui-pt-6">
          <SkeletonLine variant="multi" numberOfLines={3} />
        </div>
      </DrawerBody>
    </Drawer>
  );
};

export default DeliveryContentDrawerLoader;

DeliveryContentDrawerLoader.propTypes = {
  handleOnClose: PropTypes.func,
  isQuickViewOpen: PropTypes.bool
};

DeliveryContentDrawerLoader.defaultProps = {
  handleOnClose: () => {},
  isQuickViewOpen: false
};
