import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { getLongDescription, getShipModeDetails } from '../../helpers/utils';
import { BODFS_DESC, BUY_ONLINE_DELIVER_FROM_STORE, SHIP_TO_HOME } from '../../helpers/constants';

export const DeliveryOptionCard = ({
  deliveryType,
  deliveryOption
}) => (
  <div>
    <Typography>
      <div className="sui-grid sui-grid-cols-12">
        <div className="sui-font-bold sui-col-span-10 sui-pb-1">
          {deliveryType === BUY_ONLINE_DELIVER_FROM_STORE ? BODFS_DESC : deliveryOption.mode.desc}
        </div>

        <div className="sui-col-span-2 sui-text-end sui-font-bold">
          {deliveryOption.totalCharge === 0
            ? <span className="sui-text-success">FREE</span> : `$${deliveryOption.totalCharge}`}
        </div>
      </div>
    </Typography>

    {deliveryType === SHIP_TO_HOME && (
      <Typography>
        {getLongDescription(deliveryOption.mode.longDesc)}
      </Typography>
    )}

    {deliveryType === BUY_ONLINE_DELIVER_FROM_STORE && (
      <Typography>
        {getShipModeDetails()}
      </Typography>
    )}
    <br />
  </div>
);

DeliveryOptionCard.propTypes = {
  deliveryType: PropTypes.string.isRequired,
  deliveryOption: PropTypes.PropTypes.shape({
    mode: PropTypes.shape({
      desc: PropTypes.string,
      longDesc: PropTypes.string
    }),
    totalCharge: PropTypes.number,
  }),
};

DeliveryOptionCard.defaultProps = {
  deliveryOption: null,
};
